/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// import { useState } from "react";
// @mui material components
import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { FormControlLabel, TableCell, TextField } from "@material-ui/core";
// import Table from "@mui/material/Table";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import EditIcon from "@mui/icons-material/Edit";
import ConfirmationAlert from "components/ConfirmationAlert";
import client from "ApiClient";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Chip, CircularProgress, Grid, IconButton, Switch, Tooltip } from "@mui/material";
import CustomToolbarSelect from "./customToolbarSelect";
import Edit from "./edit";

/* eslint-disable */
export default function Table(props) {
  const [acconuntsData, setAcconuntsData] = useState([]);
  const [acconuntsDataAux, setAcconuntsDataAux] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [viewDisabled, setViewDisabled] = useState(false);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [users, setUsers] = useState([]);
  const [senderSearch, setSenderSearch] = useState("");
  const [senders, setSenders] = useState(props.senders != undefined ? props.senders : []);
  const [usersSelected, setUsersSelected] = useState([]);
  const [openLog, setOpenLog] = useState(false);
  const handleOpenModal = () => setOpenModal(true);

  const oldRender = TableCell.render;
  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };

  const options = {
    method: "GET",
    url: "users",
  };

  const getSenders = async () => {
    options.method = "GET";
    options.url = `emailSenders`;
    await client
      .request(options)
      .then((response) => {
        setSenders(response);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getData = async () => {
    options.method = "GET";
    options.url = `users`;
    await client
      .request(options)
      .then((response) => {
        if (JSON.stringify(response) !== "[{}]") setAcconuntsDataAux(response);
        setAcconuntsData(
          viewDisabled ? response : response.filter((user) => user.user_enabled === true)
        );
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const searchBySenderProfile = async () => {
    options.method = "GET";
    options.url = `users/search?str=${senderSearch}`;
    await client
      .request(options)
      .then((response) => {
        setAcconuntsData(
          viewDisabled ? response : response.filter((user) => user.user_enabled === true)
        );
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    setAcconuntsData(
      viewDisabled
        ? acconuntsDataAux
        : acconuntsDataAux.filter((user) => user.user_enabled === true)
    );
  }, [viewDisabled]);

  useEffect(() => {
    if (senderSearch.length > 3) searchBySenderProfile();
    if (senderSearch.length == 0)
      setAcconuntsData(
        viewDisabled
          ? acconuntsDataAux
          : acconuntsDataAux.filter((user) => user.user_enabled === true)
      );
  }, [senderSearch]);

  useEffect(() => {
    setIsLoading(true);
    getSenders();
    getData();
  }, [props.groupAdded]);

  const handleDelete = () => {
    setIsLoading(true);
    options.method = "DELETE";
    options.url = `users`;
    options.data = JSON.stringify(usersSelected);
    options.headers = {
      "content-type": "application/json",
    };

    client
      .request(options)
      .then((response) => {
        // setIsLoading(false);
        setOpenAlert(false);
        getData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("ERROR", error);
      });
  };

  const getStatus = (user) => {
    switch (user.user_enabled) {
      case true:
        return <Chip label="Enabled" color="success" style={{ color: "#fff", width: "150px" }} />;
      case false:
        return <Chip label="Disabled" color="error" style={{ color: "#fff", width: "150px" }} />;
    }
  };

  const columns = [
    {
      label: "Name",
      name: "user_name",
      options: {
        filterOptions: { fullWidth: true },
        filter: false,
      },
    },
    {
      label: "Email",
      name: "user_email",
      options: {
        filter: false,
        // customBodyRenderLite: (dataIndex, rowIndex) => getStatus(acconuntsData[dataIndex]),
      },
    },
    {
      label: "Status",
      name: "user_enabled",
      options: {
        filterOptions: { fullWidth: true },
        filter: false,
        customBodyRenderLite: (dataIndex, rowIndex) => getStatus(acconuntsData[dataIndex]),
      },
    },
    {
      label: "Creation date",
      name: "user_creationDate",
      options: {
        filter: true,
        customBodyRender: (value) =>
          value == null ? "N/A" : moment(value).format("MM/DD/yyyy HH:mm:ss"),
        sort: false,
        display: "true",
        filterType: "custom",
        filterList: [dateFrom, dateTo],
        customFilterListOptions: {
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, null);
              setDateFrom(null);
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1, null);
              setDateTo(null);
            } else if (filterPos === -1) {
              filterList[index] = [null, null];
              setDateFrom(null);
              setDateTo(null);
            }

            return filterList;
          },
          render: (v) => {
            if (v[0] != null && v[1] != null) {
              return [
                `Start Date: ${v[0].format("MM/DD/yyyy ")}`,
                `End Date: ${v[1].format("MM/DD/yyyy")}`,
              ];
            } else if (v[0] != null) {
              return `Start Date: ${v[0].format("MM/DD/yyyy")}`;
            } else if (v[1] != null) {
              return `End Date: ${v[1].format("MM/DD/yyyyy")}`;
            }
            return [];
          },
        },
        filterOptions: {
          logic: (dateTime, filters) => {
            if (moment(dateTime).isValid()) {
              //  console.log(filters);
              if (filters[0] && filters[1]) {
                return !(
                  moment(dateTime).diff(filters[0], "seconds") >= 0 &&
                  moment(dateTime).diff(filters[1], "seconds") <= 0
                );
              } else if (filters[0]) {
                return !(moment(dateTime).diff(filters[0], "seconds") >= 0);
              } else if (filters[1]) {
                return !(moment(dateTime).diff(filters[1], "seconds") <= 0);
              }
            }
            return false;
          },
          display: (filterList, onChanges, index, column) => {
            return (
              <>
                <MDBox style={{ width: "50vh" }}></MDBox>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker
                    labelPlacement="top"
                    label="Select start date"
                    inputFormat="MM/DD/yyyy HH:mm:ss"
                    value={dateFrom}
                    onChange={(newValue) => {
                      filterList[3][0] = newValue;
                      setDateFrom(newValue);
                      onChanges(filterList[3], index, column);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <br />
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker
                    labelPlacement="top"
                    label="Select end date"
                    inputFormat="MM/DD/yyyy HH:mm:ss"
                    value={dateTo}
                    onChange={(newValue) => {
                      filterList[3][1] = newValue;
                      setDateTo(newValue);
                      onChanges(filterList[3], index, column);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </>
            );
          },
        },
      },
    },
    {
      label: "API key",
      name: "user_apiKey",
      options: {
        filterOptions: { fullWidth: true },
        filter: false,
      },
    },
    {
      label: " ",
      name: "user_id",
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return (
            <div>
              <IconButton
                onClick={() => {
                  setUsers(acconuntsData[dataIndex]);
                  setOpenLog(true);
                }}
                sx={{ fontWeight: "bold" }}
                color="primary"
                aria-label="prompt"
              >
                <Tooltip id="button-report" title="Edit">
                  <EditIcon />
                </Tooltip>
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  const customToolbar = () => <CustomToolbarSelect onDelete={() => setOpenAlert(true)} />;

  const tableOptions = {
    textLabels: {
      body: {
        noMatch: "you don't have any subscriber",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "Clear filter",
      },
      filterType: "dropdown",
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "subscriber/s selected",
      },
    },
    print: false,
    filter: true,
    viewColumns: false,
    selectableRowsHideCheckboxes: false,
    selectableRowsOnClick: false,
    responsive: "standard",
    filterType: "dropdown",
    selectableRows: "multiple",
    fixedHeader: true,
    fixedSelectColumn: true,
    downloadOptions: { filename: "users.csv", separator: "," },
    onRowSelectionChange: (curRowSelected, allRowsSelected) => {
      let selected = [];
      allRowsSelected.map((row) => {
        selected.push(acconuntsData[row.dataIndex]);
      });
      setUsersSelected(selected);
    },
    customToolbarSelect: customToolbar,
    options: {
      setTableProps: () => {
        return {
          // material ui v4 only
          size: "small",
        };
      },
    },
  };

  return (
    <div>
      <ConfirmationAlert
        openModal={openAlert}
        onClose={() => setOpenAlert(false)}
        onYes={handleDelete}
        yesText="Yes"
        title="Are you sure you want to delete this user?"
      />
      <Edit
        openModal={openLog}
        closeModal={() => {
          getData();
          setOpenLog(false);
        }}
        user={users}
        senders={senders}
      />
      <MDBox
        mx={2}
        mt={1}
        py={3}
        px={2}
        bgColor="badgeColors.primary"
        borderRadius="lg"
        variant="gradient"
      >
        <MDTypography variant="h2">
          Users
          {isLoading && (
            <CircularProgress size={30} style={{ marginLeft: 15, position: "relative", top: 4 }} />
          )}
        </MDTypography>
        <Tooltip title={"Show disabled users"}>
          <FormControlLabel
            style={{ marginLeft: 10, marginBottom: 10 }}
            value="end"
            labelPlacement="end"
            control={
              <Switch
                checked={viewDisabled}
                onChange={() => {
                  setViewDisabled(!viewDisabled);
                }}
              />
            }
            label={<MDTypography variant="subtitle1">Show disabled users</MDTypography>}
          />
        </Tooltip>
        <MDBox>
          <FormControlLabel
            style={{ marginLeft: 10, marginBottom: 10 }}
            value="start"
            labelPlacement="start"
            control={
              <MDInput value={senderSearch} onChange={(e) => setSenderSearch(e.target.value)} />
            }
            label={<MDTypography variant="subtitle1">Search by sender profile: </MDTypography>}
          />
        </MDBox>
      </MDBox>

      <Grid container p={2}>
        <Grid item xs={12}>
          <div style={{ height: "100%", width: "100%" }}>
            <MUIDataTable data={acconuntsData} columns={columns} options={tableOptions} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
